import clsx from 'clsx';
import { useReadLocalStorage, useWindowSize } from 'usehooks-ts';
import { shallow } from 'zustand/shallow';
import { PaymentReplacementReasonTypes, PaymentStatusType } from '@/constants/paymentStatuses';
import { useSellCrypto } from '@/store/sellCrypto';
import { Countdown, Icon, MonitoredBy, SellCryptoLoader } from '@/components/common';
import { formatNumberWithCommas, getLinkToTxInBlockchain, truncateString } from '@/utils/functions';
import SockJsClient from 'react-stomp';
import { CheckoutStatusPopups } from '@/components/common/popups/CheckoutStatus';
import { ACCESS_TOKEN } from '@/constants/storage';
import { useGetSellTxn } from '@/api/queryHooks/useSellCryptoController';
import { CryptoCurrency, TxnWSMessage } from '@/api/common.types';
import { ConnectWallet, OnConnectWallet } from '@/views/BuyCrypto/components/ConnectWallet/ConnectWallet';
import { useEffect, useMemo, useState } from 'react';
import { useGetReplacedTxInfo } from '@/hooks/useGetReplacedTxInfo';
import { CryptoFrontendStatus } from '@/api/queryHooks/useTransactionsController';
import { ConfirmationPopupWithTrigger } from '@/components/common/ConfirmationPopupWithTrigger';
import { Button } from '../../../../../components/common/buttons';
import { RadioSelectGiftCard } from '../../../../../components/common/form/RadioSelectGiftCard';
import styles from './HostedCheckoutResult.module.css';

interface HostedCheckoutResultProps {
  status: PaymentStatusType;
  isGift?: boolean;
  onRefreshRate?: () => void;
  isUnderPaidMerchant?: boolean;
  cancelTxn?: () => Promise<void>;
  isCancelling?: boolean;
  manualTxStatus?: PaymentStatusType | null;
  setManualTxStatus?: (status: PaymentStatusType) => void;
}

export const HostedCheckoutResult = ({
  status,
  isGift,
  onRefreshRate,
  isUnderPaidMerchant,
  cancelTxn,
  isCancelling,
  manualTxStatus,
  setManualTxStatus,
}: HostedCheckoutResultProps) => {
  const { width } = useWindowSize();
  const accessToken = useReadLocalStorage(ACCESS_TOKEN);

  const sellState = useSellCrypto((state) => state, shallow);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isUnderPaidGift, setIsUnderPaidGift] = useState(false);

  const [isConnectedWallet] = useSellCrypto((state) => [state.isConnectedWallet], shallow);

  const counterDuration = 1500;

  const { refetch: getSellTxn } = useGetSellTxn([
    { id: sellState.txData?.id },
    {
      enabled: false,
      onSuccess(data) {
        sellState.setData({ txData: data });
      },
    },
  ]);

  function onConnect(w?: OnConnectWallet) {
    setIsWalletConnected(!!w);
  }

  const renderCheckoutInfo = (status: PaymentStatusType) => {
    switch (status) {
      case PaymentStatusType.Locked:
      case PaymentStatusType.Expired:
        return (
          <div className={styles.resultRow}>
            {width > 1000 && <Icon name="warning" size={22} className={styles.warningIcon} />}
            <span className={styles.warning}>
              External fees that originate from where your cryptocurrency is stored, needs to be factored in
            </span>
          </div>
        );
      case PaymentStatusType.Replaced:
      case PaymentStatusType.Completed:
        return (
          <div>
            {isGift && isUnderPaidGift && (
              <div className={clsx(styles.resultRow, styles.underpaidResultRow, styles.underpaidResultRowMoved)}>
                {width > 1000 && <Icon name="warning" size={22} className={styles.warningIcon} />}
                <span className={styles.underpaidWarning}>
                  The amount of crypto that was transferred was lower than expected. Rest assured we still obtained the
                  best rate for you. Please expect to see a lower amount of AUD when the transaction is complete.
                </span>
              </div>
            )}
            {isUnderPaidMerchant && (
              <div className={clsx(styles.resultRow, styles.underpaidResultRow, styles.underpaidResultRowMoved)}>
                {width > 1000 && <Icon name="warning" size={22} className={styles.warningIcon} />}
                <span className={styles.underpaidWarning}>
                  The amount of crypto that was transferred was lower than expected. The transaction is not yet
                  complete. Please either advise the customer to create another transaction for the remaining amount or
                  settle the difference externally through other means. Please expect to see a lower amount of AUD when
                  the transaction is complete.
                </span>
              </div>
            )}
            <div className={styles.resultRow}>
              {width > 1000 && <Icon name="check" size={18} className={styles.completedIcon} />}
              <div className={styles.completed}>
                <span>
                  Your payment is completed and we transferred the funds based on your instructions. A receipt of this
                  payment is sent to your email address.
                </span>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className={styles.resultRow}>
              {width > 1000 && <Icon name="warning" size={22} className={styles.warningIcon} />}
              <span className={styles.warning}>
                We&apos;ve seen your transfer on blockchain and processing your crypto payment
              </span>
            </div>
            {isGift && isUnderPaidGift && (
              <div className={clsx(styles.resultRow, styles.underpaidResultRow)}>
                {width > 1000 && <Icon name="warning" size={22} className={styles.warningIcon} />}
                <span className={styles.underpaidWarning}>
                  The amount of crypto that was transferred was lower than expected. Rest assured we still obtained the
                  best rate for you. Please expect to see a lower amount of AUD when the transaction is complete.
                </span>
              </div>
            )}
            {isUnderPaidMerchant && (
              <div className={clsx(styles.resultRow, styles.underpaidResultRow)}>
                {width > 1000 && <Icon name="warning" size={22} className={styles.warningIcon} />}
                <span className={styles.underpaidWarning}>
                  The amount of crypto that was transferred was lower than expected. The transaction is not yet
                  complete. Please either advise the customer to create another transaction for the remaining amount or
                  settle the difference externally through other means. Please expect to see a lower amount of AUD when
                  the transaction is complete.
                </span>
              </div>
            )}
          </div>
        );
    }
  };

  const { setReplacedTxId, replacedTxInfo } = useGetReplacedTxInfo();

  return (
    <>
      {isGift && (
        <>
          <SockJsClient
            url={`${process.env.NEXT_PUBLIC_API_BASE_URL}/websocket?token=${accessToken}`}
            topics={[`/topic/txs/${sellState.txData?.id}`]}
            onMessage={(message: TxnWSMessage) => {
              if (message.txStatus === CryptoFrontendStatus.REPLACED && message.replacedBy) {
                setReplacedTxId(message.replacedBy);
              }
              if (message.replacementReason === PaymentReplacementReasonTypes.UnderPaid) {
                setIsUnderPaidGift(true);
              } else {
                setIsUnderPaidGift(false);
              }
              getSellTxn();
            }}
          />

          <CheckoutStatusPopups
            status={manualTxStatus || status}
            onRetry={onRefreshRate}
            replacedTxInfo={replacedTxInfo}
            flowType="sell"
          />
        </>
      )}
      <div className={clsx(styles.additionalWrapper, { [styles.additionalSellCryptoWrapper]: isGift })}>
        <div className={clsx(styles.hostedCheckoutWrapper, { [styles.giftHostedCheckoutWrapper]: isGift })}>
          <div className={styles.main}>
            <SellCryptoLoader
              status={status}
              qrValue={sellState.txData?.depositQrCode}
              txId={sellState.txData?.id}
              isHosted
              isQRShown={!isConnectedWallet}
              className={styles.qr}
            />
            <div className={styles.hostedCheckoutInner}>
              <div className={styles.info}>
                <div className={styles.result}>
                  <div className={styles.resultRow}>
                    <div className={clsx({ [styles.valueBlock]: width < 1000 })}>
                      <div className={clsx({ [styles.valueWrapper]: width < 1000 })}>
                        <span className={clsx(styles.summarySubtitle, { [styles.valueTitle]: width < 1000 })}>
                          Send Exactly:
                        </span>
                        <div className={styles.value}>
                          {sellState.txData?.cryptoAmount} {sellState.txData?.cryptoCurrency}
                        </div>
                      </div>
                      <div className={styles.rate}>
                        Rate:
                        <span className={styles.fontMedium}>
                          1 {sellState.txData?.cryptoCurrency} = {sellState.rate} {sellState.txData?.fiatCurrency}
                        </span>
                      </div>
                      {isUnderPaidMerchant && sellState.actualAmountSent !== null && (
                        <div className={styles.rate}>
                          Underpaid amount:
                          <span className={styles.fontMedium}>
                            ~ {Number(sellState.txData?.cryptoAmount) - Number(sellState.actualAmountSent)}{' '}
                            {sellState.txData?.cryptoCurrency}
                          </span>
                        </div>
                      )}
                    </div>
                    {(status === PaymentStatusType.Locked || status === PaymentStatusType.Expired) && (
                      <div className={styles.timerWrapper}>
                        <div className={styles.countdownWrapper}>
                          <Countdown
                            duration={counterDuration}
                            status={manualTxStatus || status}
                            onComplete={() => setManualTxStatus && setManualTxStatus(PaymentStatusType.Expired)}
                          />
                        </div>
                        {width > 1000 && (
                          <div className={styles.column}>
                            <p className={styles.title}>
                              {status === PaymentStatusType.Locked ? 'Your rate is locked.' : 'Your rate had expired.'}
                            </p>
                            {status === PaymentStatusType.Locked ? (
                              <p className={styles.description}>
                                We&apos;re waiting for your payment now.
                                <br />
                                Time remaining:
                              </p>
                            ) : (
                              <Button variants="subtle" size="mediumSubtle" icon="retry" onPress={onRefreshRate}>
                                Refresh rate
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {width > 1000 && <div className={styles.divider} />}
                  {renderCheckoutInfo(status)}
                  {width > 1000 && status === PaymentStatusType.Locked && <div className={styles.divider} />}
                  {status === PaymentStatusType.Locked && (
                    <div className={styles.payNowButtonWrapper}>
                      <ConnectWallet
                        isMerchant
                        onConnect={onConnect}
                        {...(isWalletConnected && {
                          paymentOptions: {
                            depositAddress: sellState.txData?.depositAddress ?? sellState.txData?.systemDepositAddress,
                            cryptoAmount: sellState.txData?.cryptoAmount,
                            cryptoCurrency: sellState.txData?.cryptoCurrency,
                            transactionId: sellState.txData?.id,
                            selectedNetwork: sellState?.txData?.cryptoNetwork,
                          },
                        })}
                      />
                    </div>
                  )}
                  <div className={styles.resultRow}>
                    {status === PaymentStatusType.Locked && !!cancelTxn && (
                      <div className={styles.cancelButtonWrapper}>
                        <ConfirmationPopupWithTrigger
                          triggerButtonProps={{
                            variants: 'subtleWarning',
                            size: 'mediumSubtle',
                            children: 'Cancel Transaction',
                          }}
                          onSubmit={cancelTxn}
                          isLoading={isCancelling}
                        />
                      </div>
                    )}
                    {status === PaymentStatusType.Locked && (
                      <Button
                        variants="secondary"
                        icon="retry"
                        onPress={onRefreshRate}
                        className={styles.restartButton}
                      >
                        Restart Transaction
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {isGift && status !== PaymentStatusType.Locked && width > 1200 && (
                <RadioSelectGiftCard
                  isSmall
                  worth={sellState.cardAmount}
                  value={sellState.cardAmount}
                  className={clsx(styles.giftCard, 'fade-in-gift')}
                />
              )}
            </div>
          </div>
          {isGift ? (
            <div className={styles.giftSummary}>
              <div className={styles.giftSummaryRow}>
                <div className={styles.giftSummaryTitle}>Summary:</div>
              </div>
              <div className={clsx(styles.giftSummaryRow, styles.giftSummaryRowBorder)}>
                <span className={styles.giftSummarySubtitle}>Payment Type</span>
                <span className={styles.giftSummaryType}>Gift Card</span>
              </div>
              <div className={styles.giftSummaryRow}>
                <span className={styles.giftSummarySubtitle}>Card Amount</span>
                <span className={styles.giftCurrencyTitle}>
                  ${sellState.txData?.fiatAmount} {sellState.txData?.fiatCurrency}
                </span>
              </div>
              {sellState.txData?.hasMemo && (
                <div className={styles.summaryCell}>
                  <span className={styles.summarySubtitle}>Memo:</span>
                  <span className={styles.summaryTitle}>{sellState.txData?.depositMemo}</span>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.summary}>
              {width < 1000 && (
                <div className={styles.summaryCell}>
                  <span className={styles.summaryHead}>Summary:</span>
                </div>
              )}
              <div className={styles.summaryCell}>
                {width > 1000 && <span className={styles.summaryHead}>Summary:</span>}
                <span className={styles.summarySubtitle}>Invoice Number</span>
                <span className={styles.summaryTitle}>{sellState.billInvoice}</span>
              </div>
              <div className={styles.summaryCell}>
                <span className={styles.summarySubtitle}>Network</span>
                <span className={styles.summaryTitle}>{sellState.network}</span>
              </div>
              <div className={styles.summaryCell}>
                <span className={styles.summarySubtitle}>Amount</span>
                <span className={styles.summaryTitle}>
                  {formatNumberWithCommas(sellState.txData?.fiatAmount)} {sellState.txData?.fiatCurrency}
                </span>
              </div>
              <div className={styles.summaryCell}>
                <span className={styles.summarySubtitle}>Payer Email</span>
                <span className={styles.summaryTitle}>{sellState.email}</span>
              </div>
              {sellState.txData?.hasMemo && (
                <div className={styles.summaryCell}>
                  <span className={styles.summarySubtitle}>Memo:</span>
                  <span className={styles.summaryTitle}>{sellState.txData?.depositMemo}</span>
                </div>
              )}
            </div>
          )}
          {!isGift && width < 1000 && <MonitoredBy className={styles.monitoredBy} />}
        </div>
      </div>
    </>
  );
};
