import clsx from 'clsx';
import React, { useContext, useRef } from 'react';
import Image from 'next/image';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useRadioGroupState } from '@react-stately/radio';
import { useWindowSize } from 'usehooks-ts';
import { onEnterPressRadio } from '../../../../utils/events';
import styles from './RadioSelectGiftCard.module.css';
import { RadioContext, RadioGroupProps, RadioProps } from '../Radio/Radio';
import { Logo } from '../../Logo';

export const RadioSelectGiftCardGroup = ({
  className,
  ariaLabel,
  ...props
}: RadioGroupProps) => {
  const { children } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps } = useRadioGroup(
    { ...props, 'aria-label': ariaLabel },
    state,
  );

  return (
    <div {...radioGroupProps} className={clsx(styles.group, className)}>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
};

export interface RadioSelectGiftCardProps {
  worth?: string;
  currency?: string;
  isSmall?: boolean;
}

export const RadioSelectGiftCard = ({
  className,
  contentClassName,
  worth,
  currency = 'AUD',
  isSmall,
  ...props
}: RadioProps & RadioSelectGiftCardProps) => {
  const { width } = useWindowSize();
  const ref = useRef<HTMLInputElement>(null);
  const state = useContext(RadioContext);
  const { inputProps } = useRadio(
    {
      ...props,
      onKeyUp: onEnterPressRadio(props, state, props.value),
    },
    state,
    ref,
  );
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();
  const isChecked = !!inputProps.checked;
  return (
    <label
      className={clsx(styles.wrapper, className, {
        [styles.wrapperChecked]: isChecked,
      })}
    >
      <VisuallyHidden>
        <input
          {...mergeProps(inputProps, focusProps)}
          className={styles.input}
          ref={ref}
          type="radio"
        />
      </VisuallyHidden>
      <div className={clsx(styles.inner, contentClassName)}>
        {!isSmall && (
          <div
            className={clsx(styles.radio, {
              [styles.checked]: isChecked,
              [styles.focused]: isFocused,
            })}
          >
            {isChecked && (
              <span
                className={clsx(styles.radioDot, {
                  [styles.checkedRadioDot]: isChecked,
                  [styles.focused]: isFocused,
                })}
              />
            )}
          </div>
        )}
        {width > 1000 ? (
          <Image
            src="/assets/GiftCardsBg.svg"
            alt="Card"
            width={!isSmall ? 90 : 77}
            height={!isSmall ? 80 : 60}
            className={styles.giftCardsBg}
            priority
            loading="eager"
          />
        ) : (
          <Image
            src="/assets/GiftCardsBg.svg"
            alt="Card"
            width={45}
            height={40}
            className={styles.giftCardsBg}
            priority
            loading="eager"
          />
        )}
        <div
          className={clsx(styles.value, {
            [styles.valueSmall]: isSmall,
            [styles.innocent]: worth === '250',
            [styles.pink]: worth === '500',
            [styles.orange]: worth === 'Custom',
          })}
        >
          {worth} {worth !== 'Custom' && currency}
        </div>
        <Logo
          className={clsx(styles.logo, { [styles.logoSmall]: isSmall })}
          fill="white"
        />
      </div>
    </label>
  );
};

export default RadioSelectGiftCard;
