import { Popup } from '@/components/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { CryptoCurrency } from '@/api/common.types';
import { ConnectWallet, IConnectWalletRef } from '@/views/BuyCrypto/components/ConnectWallet/ConnectWallet';
import { ReadyToTransfer } from '@/components/common/popups';
import { OverlayTriggerState } from 'react-stately';
import { useSellCrypto } from '@/store/sellCrypto';
import { IsERC20Network } from '@/utils/functions';
import { useRouter } from 'next/router';
import { Routes } from '@/constants/routes';
import styles from './GetStartedPopupWithWallets.module.css';

interface GetStartedPopupWithWalletsProps {
  overlayState: OverlayTriggerState;
  onGoToCheckout: (userWalletAddress?: string) => void;
  selectedCrypto: CryptoCurrency;
  isLoadingSuccessBtn?: boolean;
  isHosted?: boolean;
  selectedNetwork?: CryptoCurrency;
}

export const GetStartedPopupWithWallets = ({
  overlayState,
  onGoToCheckout,
  selectedCrypto,
  selectedNetwork,
  isLoadingSuccessBtn,
  isHosted,
}: GetStartedPopupWithWalletsProps) => {
  const { isDesktop } = useMediaQuery();
  const [activeCard, setActiveCard] = useState<null | number>(null);
  const connectWalletRef = useRef<IConnectWalletRef>(null);
  const setSellData = useSellCrypto((state) => state.setData);
  const router = useRouter();

  function selectCard(idx: number) {
    setActiveCard(idx);
  }

  async function onGoToCheckoutWithoutWallet() {
    await onGoToCheckout();
    setActiveCard(null);
    overlayState.close();
  }

  useEffect(() => {
    if (!overlayState.isOpen) {
      setActiveCard(null);
    }
  }, [overlayState.isOpen]);

  useEffect(() => {
    switch (activeCard) {
      case 0: {
        connectWalletRef.current?.disconnect();
        setSellData({ isConnectedWallet: false, userWalletAddress: undefined });
        break;
      }
      case 1: {
        if (!IsERC20Network(selectedNetwork)) {
          //   do nothing
          setSellData({ isConnectedWallet: false, userWalletAddress: undefined });
        } else {
          connectWalletRef.current?.connect().then((wallets) => {
            if (wallets.length === 0) {
              //   do nothing
              setSellData({ isConnectedWallet: false, userWalletAddress: undefined });
              onGoToCheckout();
              return;
            }
            setSellData({ userWalletAddress: wallets?.[0]?.accounts?.[0]?.address });
            setSellData({ isConnectedWallet: true });
            onGoToCheckout(wallets?.[0]?.accounts[0]?.address);
          });
          overlayState.close();
          const NEXTContainer = document.querySelector('#__next') as HTMLElement;
          if (NEXTContainer) {
            NEXTContainer.style.filter = 'blur(5px)';
          }
        }
        break;
      }
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCard]);

  return (
    <>
      <ConnectWallet ref={connectWalletRef} />

      <Popup
        isOpen={overlayState.isOpen}
        onClose={() => {
          if (router.pathname === Routes.MERCHANT_PAYMENT) {
            router.back();
          }

          return overlayState.close();
        }}
        onSuccess={onGoToCheckoutWithoutWallet}
        successButtonText="I’m ready to start"
        successButtonClass={styles.successButton}
        successButtonSize={isDesktop ? 'large' : 'medium'}
        isDismissable={false}
        hasCloseIcon
        dialogClassName={styles.dialog}
        isLoadingSuccessBtn={isLoadingSuccessBtn}
        isDisabledSuccessBtn={activeCard === null}
        footerContent={
          <p className={styles.boilerplate}>
            By proceeding, you agree to our{' '}
            <a
              className={styles.boilerplateLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.relaypay.io/terms-and-conditions"
            >
              Terms & Conditions
            </a>
            ,{' '}
            <a
              className={styles.boilerplateLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.relaypay.io/privacy-policy"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              className={styles.boilerplateLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.relaypay.io/ecommerce-terms-and-conditions"
            >
              Merchant Terms
            </a>
            , and you acknowledge that you have read our{' '}
            <a
              className={styles.boilerplateLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://acrobat.adobe.com/id/urn:aaid:sc:AP:f5e368bf-a807-4cf2-bd9f-e5baa68742f0?viewer%21megaVerb=group-discover"
            >
              Product Disclosure Statement (PDS)
            </a>{' '}
            and{' '}
            <a
              className={styles.boilerplateLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://acrobat.adobe.com/id/urn:aaid:sc:AP:999cfcfe-7a96-475c-a60d-8f7aa979a1ba?viewer%21megaVerb=group-discover"
            >
              Target Market Determination (TMD)
            </a>
            .
          </p>
        }
      >
        <ReadyToTransfer activeCard={activeCard} selectCard={selectCard} />
      </Popup>
    </>
  );
};
